@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');

$family-sans-serif: "Raleway", sans-serif;

$sombre: #38383a;
$rouge: #e83f45;

$primary: $rouge;
$link: $rouge;
$link-focus-border: $rouge;

///@import "bulma/sass/utilities/_all";
    @import "bulma/sass/utilities/initial-variables";
    @import "bulma/sass/utilities/functions";
    @import "bulma/sass/utilities/derived-variables";


$navbar-background-color: $sombre;
$navbar-item-color: $white;
$navbar-item-hover-color: $rouge;
$navbar-item-hover-background-color: transparent;
$navbar-dropdown-arrow: $white;
$navbar-dropdown-border-top: 2px solid $rouge;
$navbar-dropdown-radius: 0;
$footer-background-color: $sombre;
$footer-color: #CCC;
$footer-padding: 0.5rem 1.5rem 0.5rem;

    @import "bulma/sass/utilities/mixins";
    @import "bulma/sass/utilities/controls";
    @import "bulma/sass/utilities/extends";

@import "bulma/sass/base/_all";

//@import "bulma/sass/elements/_all";
    //@import "bulma/sass/elements/box.sass";
    @import "bulma/sass/elements/button.sass";
    @import "bulma/sass/elements/container.sass";
    @import "bulma/sass/elements/content.sass";
    @import "bulma/sass/elements/icon.sass";
    @import "bulma/sass/elements/image.sass";
    @import "bulma/sass/elements/notification.sass";
    //@import "bulma/sass/elements/progress.sass";
    @import "bulma/sass/elements/table.sass";
    @import "bulma/sass/elements/tag.sass";
    @import "bulma/sass/elements/title.sass";
    @import "bulma/sass/elements/other.sass";

//@import "bulma/sass/form/_all";
    @import "bulma/sass/form/shared.sass";
    @import "bulma/sass/form/input-textarea.sass";
    @import "bulma/sass/form/checkbox-radio.sass";
    @import "bulma/sass/form/select.sass";
    @import "bulma/sass/form/file.sass";
    @import "bulma/sass/form/tools.sass";

//@import "bulma/sass/components/_all";
    @import "bulma/sass/components/breadcrumb.sass";
    //@import "bulma/sass/components/card.sass";
    //@import "bulma/sass/components/dropdown.sass";
    @import "bulma/sass/components/level.sass";
    //@import "bulma/sass/components/media.sass";
    @import "bulma/sass/components/menu.sass";
    //@import "bulma/sass/components/message.sass";
    //@import "bulma/sass/components/modal.sass";
    @import "bulma/sass/components/navbar.sass";
    //@import "bulma/sass/components/pagination.sass";
    //@import "bulma/sass/components/panel.sass";
    //@import "bulma/sass/components/tabs.sass";

//@import "bulma/sass/grid/_all";
    @import "bulma/sass/grid/columns.sass";
    //@import "bulma/sass/grid/tiles.sass";

//@import "bulma/sass/helpers/_all";
    //@import "bulma/sass/helpers/color";
    @import "bulma/sass/helpers/flexbox";
    @import "bulma/sass/helpers/float";
    @import "bulma/sass/helpers/other";
    @import "bulma/sass/helpers/overflow";
    @import "bulma/sass/helpers/position";
    @import "bulma/sass/helpers/spacing";
    @import "bulma/sass/helpers/typography";
    @import "bulma/sass/helpers/visibility";

//@import "bulma/sass/layout/_all";
    @import "bulma/sass/layout/hero.sass";
    //@import "bulma/sass/layout/section.sass";
    @import "bulma/sass/layout/footer.sass";

html {
    font-size: 14px;
    @include tablet {
        font-size: 16px;
    }
    @include desktop {
        font-size: 18px;
    }
}

#main-content {
    min-height: calc(100vh - 100px);
}

.footer a {
    color: #CCC;
}

.title {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: calc(0.5rem + 1px);
    margin-top: 1em;
}

.title-inner {
    border-bottom: 2px solid $rouge;
    padding-bottom: 0.5rem;
    margin-bottom: -10px;
    display: inline-flex;
}

.subtitle.orga {
    color: $white;
    background-color: #dd3333;
    padding: 0.5rem;
    margin: 2rem 0;
}

.navbar {
    .navbar-dropdown {
        box-shadow: 0 0 0 1px rgba(0,0,0,0.25);
        background-color: $white;
        a.navbar-item {
            color: $black;
        }
    }
}

#nav-toggle-state {
    display: none;
}

#nav-toggle-state:checked ~ .navbar-menu {
    display: block;
}

.badges-link {
  color: $white;
  background-color: $sombre;
  text-align: center;
  display: block;
  font-weight: bold;
  padding: 0.25rem;
  margin: 2rem 0;
  &:hover {
    color: $rouge;
  }
}

.splash-link {
    display: inline-flex;
    margin: 0.5rem 2rem;
    text-align: center;
    .splash-link-inner {
        width: 250px;
    }
    &:hover {
        h2 {
            color: $rouge;
            text-decoration: underline;
        }
    }
}
